/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneCheckoutBody as ResolvedStandaloneCheckoutBody } from 'sites/mabra/components/standalone/CheckoutBody';

export const CheckoutBody = ResolvedStandaloneCheckoutBody;

export type CheckoutBodyProps = PropsFromComponent<typeof CheckoutBody>;

export const CheckoutBodyWith = (extras: DynamicStandaloneExtras): typeof CheckoutBody => {
    return function CheckoutBody(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'CheckoutBody');
        return <ResolvedStandaloneCheckoutBody {...mergeProps({ options: { theme } }, props)} />;
    }
}