/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "input_colors_default": "border-gray-300 hover:ring-success-200 focus:border-success-700 focus:ring-success-200 active:border-gray-600 active:bg-gray-100 before:checked:border-white checked:bg-gradient-to-b checked:from-success-400 checked:to-success-500 checked:border-transparent checked:active:border-success-700",
  "input_colors_alternative": "shadow-gray4 hover:ring-success-200 focus:border-success-700 focus:ring-success-200 active:bg-success-200 checked:shadow-none checked:bg-cherry-blossom-400 checked:bg-cherry-blossom-400 checked:focus:ring-success-200 checked:focus:border-cherry-blossom-500 checked:active:bg-cherry-blossom-400 before:checked:bg-transparent before:checked:border-white before:bg-black after:bg-black",
  "input_size_default": "h-6 w-6 before:checked:h-4 before:checked:w-2",
  "input_size_alternative": "h-9 w-9 before:checked:h-4.5 before:checked:w-2.5 before:h-0.5 before:w-5 after:h-5 after:w-0.5",
  "input_variant_default": "box-border appearance-none border-2 rounded clip-path-circle-[65%_at_50%_50%] outline-0 hover:ring-8 focus:ring-8 focus:clip-path-circle-[70%_at_50%_50%] active:ring-0 before:block before:checked:border-b-3 before:checked:border-r-3 before:checked:origin-center before:checked:rotate-45 before:checked:ml-1.5",
  "input_variant_alternative": "appearance-none rounded-full outline-0 hover:ring-4 focus:border-2 focus:ring-4 active:border-transparent active:ring-0 checked:active:shadow-none checked:active:ring-0 checked:active:border-transparent checked:focus:border-2 checked:focus:ring-4 before:block after:block before:checked:border-b-2 before:checked:border-r-2 before:checked:origin-center before:checked:rotate-45 before:checked:my-1.75 before:checked:mx-3 before:focus:checked:my-1.25 before:focus:checked:mx-2.5 before:my-4.25 before:focus:my-3.75 before:mx-2 before:focus:mx-1.5 after:mx-4.25 after:relative after:bottom-7 after:focus:bottom-6.5 after:focus:right-0.5 after:checked:hidden",
  "label_colors_default": "text-black",
  "label_colors_alternative": "",
  "label_size_default": "text-headline-xs",
  "label_size_alternative": "",
  "label_variant_alternative": "hidden",
  "caption_colors_default": "",
  "caption_size_default": "w-full",
  "caption_variant_default": "order-1",
  "colors_default": "",
  "colors_alternative": "",
  "size_default": "gap-x-2",
  "size_alternative": "",
  "variant_default": "flex flex-row items-center",
  "variant_alternative": "relative z-1",
  "label_variant_default": "ml-2.5"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Checkbox");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;