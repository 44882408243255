/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "background_background_colors_default": "",
  "background_background_size_default": "",
  "background_background_variant_default": "",
  "content_colors_default": "",
  "content_size_default": "",
  "content_variant_default": "flex flex-col items-center",
  "content_title_colors_default": "",
  "content_title_size_default": "text-headline-xl",
  "content_title_variant_default": "",
  "content_description_colors_default": "",
  "content_description_size_default": "mt-4 text-body-md",
  "content_description_variant_default": "text-center",
  "content_image_colors_default": "",
  "content_image_size_default": "h-16 w-20",
  "content_image_variant_default": "flex",
  "colors_default": "bg-yellow-sea-100",
  "size_default": "px-8 md:px-24 lg:px-74 py-10",
  "variant_default": "relative overflow-hidden",
  "background_colors_default": "text-yellow-sea-400",
  "background_size_default": "w-full",
  "background_variant_default": "absolute -top-52 left-0"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "CheckoutHeader");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;