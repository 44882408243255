/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneCheckoutFooter as ResolvedStandaloneCheckoutFooter } from 'sites/mabra/components/standalone/CheckoutFooter';

export const CheckoutFooter = ResolvedStandaloneCheckoutFooter;

export type CheckoutFooterProps = PropsFromComponent<typeof CheckoutFooter>;

export const CheckoutFooterWith = (extras: DynamicStandaloneExtras): typeof CheckoutFooter => {
    return function CheckoutFooter(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'CheckoutFooter');
        return <ResolvedStandaloneCheckoutFooter {...mergeProps({ options: { theme } }, props)} />;
    }
}