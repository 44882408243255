import { Icon } from '@/components/Icon';
import { useScheduled } from '@/hooks/useScheduled';
import { useStable } from '@/hooks/useStable';
import FooterEllipse from '@/mabra/public/image/course/banner-ellipse.svg';
import BannerImage from '@/mabra/public/image/course/mabra-banner-image.png';
import { mergeProps } from '@/utils/merge';
import { StandaloneCheckoutFooter, StandaloneCheckoutFooterProps } from 'base/components/CheckoutFooter';
import KlarnaBadgeLogo from 'base/public/image/klarna-badge.png';
import getConfig from 'modules/config';
import NextImage from 'next/image';
import { useMemo } from 'react';

export const MabraStandaloneCheckoutFooter: typeof StandaloneCheckoutFooter = (props) => {
  const { closeTime } = getConfig('payments') ?? {};

  const logo = useMemo(() => <NextImage src={BannerImage} alt={'Banner Image'} />, []);

  const isSignUpClosed = useScheduled(closeTime);

  const footerStable = useStable({
    title: 'Anta utmaningen!',
    background: useMemo(() => <FooterEllipse />, []),
    content: useMemo(
      () => (
        <>
          {logo}
          <div>
            <Icon name="circleCheck" /> Start 2 september
          </div>
          <div>
            <Icon name="circleCheck" /> <span className="mr-1 line-through">499 kr</span>
            <span className="text-cherry-blossom-500">Nu endast 249 kr</span>
          </div>
          {!isSignUpClosed && (
            <div data-last={true}>
              Köp kursen nedan
              <Icon name="arrowDown" options={{ size: 'large' }} />
            </div>
          )}
        </>
      ),
      [logo, isSignUpClosed],
    ),
    paymentGatewayLogo: !isSignUpClosed && (
      <>
        Betala med
        <NextImage src={KlarnaBadgeLogo} alt="Klarna logo" />
      </>
    ),
  } satisfies StandaloneCheckoutFooterProps);

  return <StandaloneCheckoutFooter {...mergeProps({ ...footerStable }, props)} />;
};
