/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneCheckoutHeader as ResolvedStandaloneCheckoutHeader } from 'sites/mabra/components/standalone/CheckoutHeader';

export const CheckoutHeader = ResolvedStandaloneCheckoutHeader;

export type CheckoutHeaderProps = PropsFromComponent<typeof CheckoutHeader>;

export const CheckoutHeaderWith = (extras: DynamicStandaloneExtras): typeof CheckoutHeader => {
    return function CheckoutHeader(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'CheckoutHeader');
        return <ResolvedStandaloneCheckoutHeader {...mergeProps({ options: { theme } }, props)} />;
    }
}