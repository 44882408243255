/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "background_colors_default": "text-yellow-sea-400",
  "background_size_default": "w-full",
  "background_variant_default": "absolute bottom-0 right-0 z-0",
  "title_colors_default": "",
  "title_size_default": "text-headline-md mb-8",
  "title_variant_default": "z-10",
  "content_colors_default": "bg-white [&_svg]:text-cherry-blossom-300",
  "content_size_default": "px-8 py-4 [&_img]:h-24 [&_img]:w-28 [&_svg]:mr-1 [&>div]:m-2 [&>div]:text-headline-sm data-[last=true]:[&>div]:text-headline-2xs",
  "content_variant_default": "rounded-xl z-10 flex flex-col items-center [&>div]:flex [&>div]:flex-row [&>div]:self-start [&>div]:items-center data-[last=true]:[&>div]:flex-col data-[last=true]:[&>div]:items-center data-[last=true]:[&>div]:self-center",
  "paymentGatewayLogo_colors_default": "",
  "paymentGatewayLogo_size_default": "text-headline-2xs [&_img]:w-24 [&_img]:h-12 mt-1.5",
  "paymentGatewayLogo_variant_default": "flex flex-row items-center z-10",
  "colors_default": "bg-yellow-sea-100",
  "size_default": "p-8",
  "variant_default": "flex flex-col items-center relative overflow-hidden"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "CheckoutFooter");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;