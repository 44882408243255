import { useStable } from '@/hooks/useStable';
import CourseImage from '@/mabra/public/image/course/21-dagar.jpg';
import withTrackingAttributes from '@/utils/withTrackingAttributes';
import { StandaloneCheckout } from 'base/components/Checkout';
import Tracking from 'base/components/TrackingComponent';
import getConfig from 'modules/config';
import NextImage from 'next/image';
import { useMemo } from 'react';

export const MabraStandaloneCheckout: typeof StandaloneCheckout = (props) => {
  const { closeTime } = getConfig('payments') ?? {};

  const memoDisabledContent = useMemo(
    () => (
      <>
        <div className="relative mb-4 flex flex-col gap-y-4 py-4 wings wings-yellow-sea-100">
          <h2 className="text-headline-md">Missa inte: Nya vanor på 21 dagar</h2>
          <p className="text-body-sm">
            Med MåBra:s utmaning 21 dagar får du daglig coachning av vår erfarna kostrådgivare{' '}
            <span className="bold">Sandra Hiort</span> och träningsprofilen{' '}
            <span className="bold">Leila Söderholm!</span>
          </p>
          <p className="text-body-sm">
            Du får tillgång till kostplaner som är enkla att följa, massor av nyttiga och goda recept, träningsschema,
            exklusiva träningsvideos och daglig pepp direkt till din mejl.
          </p>
          <ul className="list-disc pl-5 text-preamble-sm">
            <li>Anmälan öppnar på denna sida den 1 augusti</li>
            <li>Utmaningen startar 2 september</li>
          </ul>
        </div>
        <NextImage src={CourseImage} alt="Mabra Fitness Course" />
      </>
    ),
    [],
  );

  const paymentStable = useStable({
    closed: {
      time: closeTime,
      copy: 'Anmälan är nu stängd, håll utkik i våra kanaler för nästa tillfälle.',
    },
    options: {
      $form: {
        className: 'cts-impression-item',
        ...withTrackingAttributes({
          object: 'signup_box',
          creative: 'signup_box',
          objectName: 'klarna_payment',
          name: 'klarna_payment',
          objectExtraInfo: [{ campaign: 'mabra_course_sep24' }],
          label: [{ campaign: 'mabra_course_sep24' }],
        }),
      },
    },
    disabledContent: memoDisabledContent,
    props,
  });

  return (
    <Tracking>
      <StandaloneCheckout {...paymentStable} />
    </Tracking>
  );
};
