import { Icon } from '@/components/Icon';
import { Link } from '@/components/Link';
import { useStable } from '@/hooks/useStable';
import { mergeProps } from '@/utils/merge';
import { StandaloneCheckoutBody } from 'base/components/CheckoutBody';
import { ReactNode, useMemo } from 'react';

export const MabraStandaloneCheckoutBody: typeof StandaloneCheckoutBody = (props) => {
  const bodyStable = useStable(
    useMemo(() => {
      const bodyListItems: ReactNode[] = [
        <>
          <Icon name="clock" />
          <h3>Nytt kostschema varje vecka</h3>
        </>,
        <>
          <Icon name="toolsKitchen" />
          <h3>Receptbank med fler än 100 recept</h3>
        </>,
        <>
          <Icon name="gym" />
          <h3>Träningsfilmer med Leila Söderholm</h3>
        </>,
        <>
          <Icon name="mailHeart" />
          <h3>Dagliga mejl med motivationstips och fördjupning</h3>
        </>,
        <>
          <Icon name="facebook" />
          <h3>Tillgång till en gemensam Facebook-grupp</h3>
        </>,
      ];
      return (
        <>
          <p>
            Du får tillgång till kostplaner som är enkla att följa, massor av nyttiga och goda recept, träningsschema
            och daglig pepp direkt till din mejl. Träningsprofilen Leila Söderholm ger dig roliga och effektiva pass
            varje dag – speciellt framtagna för 21 dagar.
          </p>
          <p>Häng med – utmaningen startar 2 september!</p>
          <p>Du får:</p>
          {bodyListItems && bodyListItems.map((item, index) => <div key={index}>{item}</div>)}
          <h3>Vad ingår i utmaningen?</h3>
          <p>
            Du får dagliga träningspass, speciellt framtagna av personliga tränaren Leila Söderholm. Du kan enkelt
            anpassa dem efter din nivå. Allt du behöver har du hemma – och det tar inte mer än en kvart!
          </p>
          <p>
            I receptbanken hittar du utvalda recept, bland annat från kostrådgivartjänsten roede.com som fokuserar på
            sund mat, fysisk aktivitet och hållbara vanor. Samtliga recept är granskade av kostrådgivare med både näring
            och smak i fokus.
          </p>
          <p>
            Du väljer själv om du vill följa en färdig kostplan eller sätta ihop din egen med hjälp av kostrådgivarens
            guide.
          </p>
          <p>
            Varje dag får du ett mejl från MåBra, fyllt med inspiration, tips och fördjupande fakta. I vår slutna
            Facebook-grupp får du pepp och stöttning från oss och andra deltagare.
          </p>

          <h3>Vem kan gå kursen?</h3>
          <p>
            Alla! Träningspassen kan anpassas efter nivå och dagsform. Kostplanerna är enkla att följa och recepten kan
            i viss mån anpassas efter preferens.
          </p>
          <Link
            content={'Vanliga frågor och svar'}
            href={'https://www.mabra.com/halsa/mabra-21-dagar-fragor-och-svar/10314614'}
            target={'_blank'}
            options={{ className: 'text-cherry-blossom-500 underline text-body-md' }}
          />
        </>
      );
    }, []),
  );
  return <StandaloneCheckoutBody {...mergeProps({ content: bodyStable }, props)} />;
};
