import { useStable } from '@/hooks/useStable';
import HeaderBackground from '@/mabra/public/image/course/checkout-header-background.svg';
import BannerImage from '@/mabra/public/image/course/mabra-banner-image.png';
import { mergeProps } from '@/utils/merge';
import { StandaloneCheckoutHeader, StandaloneCheckoutHeaderProps } from 'base/components/CheckoutHeader';
import NextImage from 'next/image';
import { useMemo } from 'react';

export const MabraStandaloneCheckoutHeader: typeof StandaloneCheckoutHeader = (props) => {
  const logo = useMemo(() => <NextImage src={BannerImage} alt={'Banner Image'} />, []);

  const headerStable = useStable({
    background: useMemo(() => <HeaderBackground />, []),
    image: logo,
    title: '21 dagar kost & träning',
    description:
      'Ge dig själv 21 dagar för att hitta nya hälsosamma vanor – som går att hålla fast vid resten av livet! Anmäl dig till MåBra:s nya utmaning där du får coachning varje dag av vår erfarna träningsexpert och kostrådgivare Sandra Hiort.',
  }) satisfies StandaloneCheckoutHeaderProps;

  return <StandaloneCheckoutHeader {...mergeProps({ ...headerStable }, props)} />;
};
